import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import LazyImg from '~/components/lazy-img';
import { urlFor } from '~/sanity/images';

import styles from '~/components/the-shed/components/article-block.module.scss';

const ArticleBlockImage = ({ className, image, to, imagePreview }) => {
  // here
  const [isLoaded, setIsLoaded] = useState(false);
  const desktopSource = urlFor(image).width(675).height(590).fit('max');
  const mobileSource = urlFor(image).width(325).height(226).fit('max');

  return (
    <Link to={to}>
      <div style={{ position: 'relative' }}>
        {!isLoaded && (
          <LazyImg
            src={imagePreview}
            className={cx(className, styles.articleBlockImage)}
            style={{ position: 'absolute', top: 0, left: 0 }}
            alt={image?.alt || ''}
          />
        )}
        <LazyImg
          className={cx(className, styles.articleBlockImage)}
          srcSet={`
          ${mobileSource.url()} 375w,
          ${desktopSource.url()}
        `}
          sizes="(max-width: 768px) 325px, 675px"
          alt={image?.alt || ''}
          width="675"
          height="590"
          onLoad={() => setIsLoaded(true)}
        />
      </div>
    </Link>
  );
};

export { ArticleBlockImage };
